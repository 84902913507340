/**
 * Configuration constants
 */

export const LOGIN_ROUTE_PATH = "/login";
export const LOGIN_POST_PATH = "/login";
export const REGISTER_POST_PATH = "/register/basicAccount";
export const CHANGE_PASSWORD_LINK_PATH = "/confirm/reset/password/request";
export const CAPTCHA_PATH = '/captcha';
export const CAPTCHA_REFRESH_PATH = '/captcha/refresh';

export const LOGIN_TAB = 'login';
export const REGISTER_TAB = 'register';

export const ErrorMessages = {
    EMPTY_RESPONSE_DATA: 'Empty response data',
    FAILED_TO_FETCH_TIME: 'Error occurred while fetching time from server',
    INTERNAL_ERROR: 'Internal error',
    AGREE_TERMS: 'You have to agree to the terms',
    READ_TERMS: 'Please read the terms in order to continue!',
    EMAIL_NOT_PROVIDED: 'Please Provide Email address',
    PHONE_NUMBER_NOT_PROVIDED: 'Please Provide Phone number',
    COUNTRY_NOT_PROVIDED: 'Please Provide Code',
    COUNTRY_INVALID: 'Provided Code is invalid',
    PASSWORD_NOT_PROVIDED: 'Please Provide Password',
    PASSWORD_CONFIRM_NOT_PROVIDED: 'Password confirmation should not be blank',
    PASSWORDS_NO_MACH: 'Passwords do not match',
};

export const MessageTypes = {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning"
};

export const Themes = {
    LIGHT: "theme-light",
    DARK: "theme-dark"
};
export const ThemeColors = {
    LIGHT: "#ffffff",
    DARK: "#161718"
};
