import {LOGIN_ROUTE_PATH} from "@/constants";
import Vue from "vue";
import Router from "vue-router";
import LoginStart from "./views/LoginStart.vue";

Vue.use(Router);

export default new Router({
    mode: "history",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes: [
        {
            path: LOGIN_ROUTE_PATH,
            name: "login",
            component: LoginStart
        }
    ]
});
