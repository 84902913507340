import {LOGIN_TAB, REGISTER_TAB} from "@/constants";

// STATE
export const state = {
    icoLogin: {...appContext.icoLogin},
    icoUserCreate: {...appContext.icoUserCreate},

    countries: appContext.countries,

    activeTab: LOGIN_TAB,

    companyTerms: appContext.companyTerms,
    companyTermsUrl: appContext.companyTermsUrl,
    promoAcceptText: appContext.promoAcceptText,

    canCheckCompTerms: true,

    checkedCompTerms: false,
    checkedPromoText: false,

    show2FA: false,
    showCaptcha: false,

    TWOFAError: undefined,
    countryCodeError: undefined,
    phoneNumberError: undefined,
    usernameError: undefined,
    passwordError: undefined,

    httpRequestInProgress: false
};

// ACTIONS
export const actions = {
    isHttpRequestInProgress() {
        return this.state.httpRequestInProgress;
    },
    switchTab(active_tab) {
        switch (active_tab) {
            case LOGIN_TAB:
            case REGISTER_TAB:
                this.state.activeTab = active_tab;
                break;
            default:
                this.state.activeTab = LOGIN_TAB;
                break;
        }
    },
    setFieldErrors(errors) {
        if (errors.countryCode) {
            this.state.countryCodeError = errors.countryCode;
        }

        if (errors.phoneNumber) {
            this.state.phoneNumberError = errors.phoneNumber;
        }

        if (errors.username) {
            this.state.usernameError = errors.username;
        }

        if (errors.email) {
            this.state.usernameError = errors.email;
        }

        if (errors.password) {
            this.state.passwordError = errors.password;
        }

        if (errors.token) {
            if (!this.state.show2FA) {
                this.state.show2FA = true;
            } else {
                this.state.TWOFAError = errors.token;
            }
        }
    },
};
