<template>
  <div class="wp-like-menu-container">
    <ul class="wp-like-menu">
      <li class="wp-like-menu-item" v-if="logo">
        <a href="https://www.fintelum.com/" class="wp-like-menu-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="132"
            height="39.435"
            viewBox="0 0 132 39.435"
          >
            <g id="Logo" transform="translate(-592.508 -321.498)">
              <g id="Group_1484" data-name="Group 1484">
                <g id="Group_1260" data-name="Group 1260">
                  <path
                    id="Path_2289"
                    data-name="Path 2289"
                    d="M629.652,343.638h-2.36a.514.514,0,0,1-.584-.435.523.523,0,0,1,0-.148v-16.7a.516.516,0,0,1,.584-.584h8.864c.371,0,.555.2.555.584v1.89q0,.583-.555.583H630.57c-.222,0-.334.1-.334.306v4.308a.3.3,0,0,0,.263.333.424.424,0,0,0,.071,0h4.752a.517.517,0,0,1,.584.439.58.58,0,0,1,0,.144v1.862c0,.408-.2.611-.584.611H630.57c-.222,0-.334.1-.334.306v5.919a.515.515,0,0,1-.439.581A.5.5,0,0,1,629.652,343.638Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2290"
                    data-name="Path 2290"
                    d="M639.072,327.076v-2.418a.492.492,0,0,1,.418-.556.533.533,0,0,1,.138,0h2.418c.37,0,.556.177.556.529v2.445a.492.492,0,0,1-.419.556.522.522,0,0,1-.137,0h-2.418a.492.492,0,0,1-.556-.416A.543.543,0,0,1,639.072,327.076Zm0,15.979V330.05c0-.39.186-.584.556-.584h2.39a.516.516,0,0,1,.584.584v13.005a.642.642,0,0,1-.125.472.654.654,0,0,1-.431.111h-2.39a.516.516,0,0,1-.584-.437A.509.509,0,0,1,639.072,343.055Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2291"
                    data-name="Path 2291"
                    d="M648.743,343.638h-2.362a.513.513,0,0,1-.583-.436.52.52,0,0,1,0-.147V330.05a.515.515,0,0,1,.583-.584h2.362a.516.516,0,0,1,.583.438.509.509,0,0,1,0,.146v.917h.112a2.61,2.61,0,0,1,2.723-1.5h.89q4.36,0,4.362,4.53v9.059c0,.389-.2.584-.611.583h-2.334a.515.515,0,0,1-.584-.583v-8.893a1.383,1.383,0,0,0-1.639-1.639h-1q-1.917,0-1.918,2.112v8.42a.513.513,0,0,1-.437.582A.454.454,0,0,1,648.743,343.638Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2292"
                    data-name="Path 2292"
                    d="M660.637,332.523h-1.112a.7.7,0,0,1-.459-.112.591.591,0,0,1-.125-.443V330.05a.517.517,0,0,1,.438-.584.511.511,0,0,1,.146,0h1.112a.27.27,0,0,0,.3-.235.273.273,0,0,0,0-.07V326.52q0-.583.612-.583h2.334a.517.517,0,0,1,.584.438.5.5,0,0,1,0,.145v2.641c0,.2.111.3.334.3h2.166a.516.516,0,0,1,.584.584v1.918a.6.6,0,0,1-.125.43.644.644,0,0,1-.459.126H664.8q-.335,0-.334.305v6.141a1.657,1.657,0,0,0,.389,1.237,1.8,1.8,0,0,0,1.278.375h1.112a.516.516,0,0,1,.583.44.5.5,0,0,1,0,.144v1.918a.6.6,0,0,1-.125.43.647.647,0,0,1-.458.125h-1.7a4.7,4.7,0,0,1-3.405-1.167,4.421,4.421,0,0,1-1.209-3.335v-6.308a.27.27,0,0,0-.23-.305A.282.282,0,0,0,660.637,332.523Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2293"
                    data-name="Path 2293"
                    d="M680.586,343.638h-6.142a4.645,4.645,0,0,1-3.389-1.167,4.485,4.485,0,0,1-1.2-3.362V334a4.489,4.489,0,0,1,1.2-3.363,4.645,4.645,0,0,1,3.389-1.168h2.2a4.646,4.646,0,0,1,3.39,1.168,4.489,4.489,0,0,1,1.2,3.363v3.528a.517.517,0,0,1-.438.584.5.5,0,0,1-.145,0h-6.976q-.333,0-.333.3v.612a1.706,1.706,0,0,0,.375,1.236,1.748,1.748,0,0,0,1.264.375h5.6q.583,0,.584.611v1.835a.6.6,0,0,1-.125.43A.642.642,0,0,1,680.586,343.638Zm-6.919-7.892h3.779a.268.268,0,0,0,.3-.234.239.239,0,0,0,0-.066v-1.334a1.346,1.346,0,0,0-1.015-1.611,1.332,1.332,0,0,0-.6,0h-1.167a1.346,1.346,0,0,0-1.612,1.015,1.331,1.331,0,0,0,0,.6v1.334a.27.27,0,0,0,.234.3.306.306,0,0,0,.08,0Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2294"
                    data-name="Path 2294"
                    d="M686.952,343.638h-2.367A.514.514,0,0,1,684,343.2a.509.509,0,0,1,0-.146v-18.37a.517.517,0,0,1,.439-.584.5.5,0,0,1,.144,0h2.362a.516.516,0,0,1,.584.438.509.509,0,0,1,0,.146v18.369a.515.515,0,0,1-.436.584A.49.49,0,0,1,686.952,343.638Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2295"
                    data-name="Path 2295"
                    d="M695.845,343.638h-.917a4.232,4.232,0,0,1-3.237-1.167,4.717,4.717,0,0,1-1.1-3.362V330.05a.516.516,0,0,1,.584-.584h2.361a.516.516,0,0,1,.584.584v8.893a1.748,1.748,0,0,0,.375,1.264,1.7,1.7,0,0,0,1.237.375h1.028q1.917,0,1.918-2.112v-8.42a.516.516,0,0,1,.438-.584.585.585,0,0,1,.145,0H701.6q.609,0,.611.584v13.006q0,.584-.611.583h-2.335a.515.515,0,0,1-.583-.583v-.917h-.112a2.107,2.107,0,0,1-1.084,1.125A3.714,3.714,0,0,1,695.845,343.638Z"
                    fill="#161718"
                  />
                  <path
                    id="Path_2296"
                    data-name="Path 2296"
                    d="M708.211,343.638h-2.362a.516.516,0,0,1-.584-.437.509.509,0,0,1,0-.146V330.05a.517.517,0,0,1,.438-.584.511.511,0,0,1,.146,0h2.362a.516.516,0,0,1,.584.584v.917h.111a2.5,2.5,0,0,1,1.125-1.084,3.837,3.837,0,0,1,1.821-.417h.778a3.722,3.722,0,0,1,3.473,1.779,3.373,3.373,0,0,1,3.252-1.779h.806a4.226,4.226,0,0,1,3.236,1.168A4.719,4.719,0,0,1,724.5,334v9.059a.514.514,0,0,1-.582.583h-2.363a.515.515,0,0,1-.583-.583v-8.893a1.794,1.794,0,0,0-.362-1.264,1.7,1.7,0,0,0-1.25-.375h-.7q-1.917,0-1.918,2.139v8.393q0,.585-.611.583h-2.334a.515.515,0,0,1-.583-.583v-8.893a1.384,1.384,0,0,0-1.07-1.639,1.4,1.4,0,0,0-.57,0h-.862q-1.917,0-1.917,2.112v8.42a.516.516,0,0,1-.438.583A.49.49,0,0,1,708.211,343.638Z"
                    fill="#161718"
                  />
                </g>
                <path
                  id="Path_2297"
                  data-name="Path 2297"
                  d="M611.393,321.5H598.37a5.862,5.862,0,0,0-5.862,5.861v13.025a5.861,5.861,0,0,0,5.861,5.86h13.024a5.861,5.861,0,0,0,5.861-5.86V327.36a5.861,5.861,0,0,0-5.861-5.862Zm1.135,7.946a.8.8,0,0,1-.8.8h-2.415a.8.8,0,0,1-.8-.8v-.914H606.9v.914a.806.806,0,0,1-.8.8h-2.415a.8.8,0,0,1-.8-.8v-.914h-1.61v.914a.791.791,0,0,1-.068.321l2.161,2.16a.808.808,0,0,1,.322-.067H606.1a.8.8,0,0,1,.8.8v2.415a.8.8,0,0,1-.8.8h-2.414a.8.8,0,0,1-.8-.795v-.924h-1.61v.914a.805.805,0,0,1-.8.8h-.914v1.61h.914a.805.805,0,0,1,.8.8v2.414a.8.8,0,0,1-.8.8h-2.416a.8.8,0,0,1-.8-.8v-2.414a.8.8,0,0,1,.795-.8h.919v-1.61h-.914a.8.8,0,0,1-.8-.8v-2.42a.8.8,0,0,1,.8-.8h2.416a.8.8,0,0,1,.8.8v.913h1.61v-.913a.8.8,0,0,1,.067-.322l-2.161-2.16a.786.786,0,0,1-.321.067h-2.416a.8.8,0,0,1-.8-.8v-2.415a.8.8,0,0,1,.8-.8h2.421a.8.8,0,0,1,.8.8v.913h1.61v-.913a.8.8,0,0,1,.794-.8h2.426a.8.8,0,0,1,.8.8v.913h1.61v-.913a.8.8,0,0,1,.795-.8h2.425a.8.8,0,0,1,.8.8v.006Z"
                  fill="#161718"
                />
              </g>
              <g id="Group_1485" data-name="Group 1485">
                <path
                  id="Path_2654"
                  data-name="Path 2654"
                  d="M598.335,357.275h1.41l-.169,1.085h-1.411l-.379,2.377h-1.28l.378-2.377h-1.672l-.378,2.377h-1.281l.379-2.377h-1.424l.17-1.085H594.1l.3-1.908h-1.424l.17-1.084h1.424l.379-2.351h1.28l-.378,2.351h1.672l.379-2.351h1.28l-.379,2.351h1.411l-.17,1.084h-1.411Zm-2.653-1.908-.3,1.908h1.672l.3-1.908Z"
                />
                <path
                  id="Path_2655"
                  data-name="Path 2655"
                  d="M605.681,352.794v7.943h-1.555v-7.943H601.07v-1.358h7.668v1.358Z"
                />
                <path
                  id="Path_2656"
                  data-name="Path 2656"
                  d="M614.869,357.419c0,2.155-1.254,3.514-3.266,3.514s-3.279-1.359-3.279-3.514,1.267-3.514,3.279-3.514S614.869,355.263,614.869,357.419Zm-4.977,0c0,1.436.653,2.338,1.711,2.338s1.711-.9,1.711-2.338-.653-2.339-1.711-2.339S609.892,355.982,609.892,357.419Z"
                />
                <path
                  id="Path_2657"
                  data-name="Path 2657"
                  d="M620.687,360.737l-2.587-3.213h-.026v3.213h-1.489v-9.3h1.489v5.382h.026l2.417-2.718h1.737l-2.743,3.031,3.057,3.606Z"
                />
                <path
                  id="Path_2658"
                  data-name="Path 2658"
                  d="M627.627,358.738h1.528a2.894,2.894,0,0,1-3.056,2.2c-2.025,0-3.214-1.45-3.214-3.592a3.134,3.134,0,0,1,3.188-3.436,2.928,2.928,0,0,1,2.978,2.26,5.242,5.242,0,0,1,.183,1.554h-4.873c.052,1.5.81,2.117,1.738,2.117A1.425,1.425,0,0,0,627.627,358.738Zm-3.24-2h3.358A1.662,1.662,0,0,0,626.073,355C625.171,355,624.518,355.59,624.387,356.74Z"
                />
                <path
                  id="Path_2659"
                  data-name="Path 2659"
                  d="M636.789,356.675v4.062H635.3V356.8c0-1.188-.509-1.658-1.346-1.658s-1.515.639-1.515,1.907v3.684H630.95V354.1h1.411v.863h.026a2.369,2.369,0,0,1,1.985-1.019C635.875,353.944,636.789,354.858,636.789,356.675Z"
                />
                <path
                  id="Path_2660"
                  data-name="Path 2660"
                  d="M638.9,352.859v-1.423h1.489v1.423Zm0,7.878V354.1h1.489v6.637Z"
                />
                <path
                  id="Path_2661"
                  data-name="Path 2661"
                  d="M647.534,356.165H646.1c0-.8-.47-1.176-1.267-1.176-.692,0-1.1.3-1.1.784,0,.574.457.692,1.5.954,1.215.313,2.508.6,2.508,2.182,0,1.2-1.071,2.024-2.782,2.024-1.777,0-2.862-.8-2.874-2.378H643.6c0,.81.5,1.267,1.385,1.267.757,0,1.2-.326,1.2-.822,0-.68-.522-.811-1.672-1.1-1.11-.274-2.272-.522-2.272-2.011,0-1.177,1.045-1.986,2.6-1.986C646.528,353.9,647.521,354.689,647.534,356.165Z"
                />
                <path
                  id="Path_2662"
                  data-name="Path 2662"
                  d="M653.835,358.738h1.528a2.9,2.9,0,0,1-3.057,2.2c-2.024,0-3.214-1.45-3.214-3.592a3.134,3.134,0,0,1,3.188-3.436,2.929,2.929,0,0,1,2.979,2.26,5.207,5.207,0,0,1,.183,1.554h-4.873c.052,1.5.809,2.117,1.737,2.117A1.426,1.426,0,0,0,653.835,358.738Zm-3.24-2h3.358A1.663,1.663,0,0,0,652.28,355C651.378,355,650.725,355.59,650.595,356.74Z"
                />
                <path
                  id="Path_2663"
                  data-name="Path 2663"
                  d="M660.292,360.737h-1.568V357l-3.265-5.565h1.776l1.019,1.841c.431.784.849,1.6,1.242,2.4h.025c.393-.809.811-1.62,1.254-2.4l1.007-1.841h1.763L660.292,357Z"
                />
                <path
                  id="Path_2664"
                  data-name="Path 2664"
                  d="M669.454,357.419c0,2.155-1.254,3.514-3.266,3.514s-3.279-1.359-3.279-3.514,1.267-3.514,3.279-3.514S669.454,355.263,669.454,357.419Zm-4.978,0c0,1.436.654,2.338,1.712,2.338s1.711-.9,1.711-2.338-.653-2.339-1.711-2.339S664.476,355.982,664.476,357.419Z"
                />
                <path
                  id="Path_2665"
                  data-name="Path 2665"
                  d="M671.1,358.164V354.1h1.489v3.933c0,1.188.51,1.658,1.346,1.658s1.516-.639,1.516-1.907V354.1h1.489v6.637h-1.411v-.863h-.026a2.37,2.37,0,0,1-1.986,1.019C672.019,360.893,671.1,359.98,671.1,358.164Z"
                />
                <path
                  id="Path_2666"
                  data-name="Path 2666"
                  d="M682.971,354.075v1.319a2.46,2.46,0,0,0-.523-.052c-1.188,0-1.841.757-1.841,2.142v3.253h-1.49V354.1H680.5v.98h.027a2.1,2.1,0,0,1,1.894-1.071A2.647,2.647,0,0,1,682.971,354.075Z"
                />
                <path
                  id="Path_2667"
                  data-name="Path 2667"
                  d="M685.651,358.216l-.862,2.521h-1.647l3.384-9.3h2.1l3.384,9.3h-1.646l-.862-2.521Zm3.136-2.09c-.418-1.242-.824-2.456-1.2-3.7h-.027c-.379,1.242-.757,2.456-1.188,3.7l-.249.731h2.913Z"
                />
                <path
                  id="Path_2668"
                  data-name="Path 2668"
                  d="M698.172,356.165h-1.437c0-.8-.47-1.176-1.268-1.176-.692,0-1.1.3-1.1.784,0,.574.458.692,1.5.954,1.215.313,2.508.6,2.508,2.182,0,1.2-1.071,2.024-2.782,2.024-1.777,0-2.861-.8-2.875-2.378h1.516c0,.81.5,1.267,1.385,1.267.758,0,1.2-.326,1.2-.822,0-.68-.523-.811-1.672-1.1-1.11-.274-2.273-.522-2.273-2.011,0-1.177,1.044-1.986,2.6-1.986C697.166,353.9,698.158,354.689,698.172,356.165Z"
                />
                <path
                  id="Path_2669"
                  data-name="Path 2669"
                  d="M705.086,356.165h-1.437c0-.8-.47-1.176-1.268-1.176-.691,0-1.1.3-1.1.784,0,.574.458.692,1.5.954,1.215.313,2.509.6,2.509,2.182,0,1.2-1.072,2.024-2.783,2.024-1.777,0-2.86-.8-2.874-2.378h1.516c0,.81.5,1.267,1.385,1.267.757,0,1.2-.326,1.2-.822,0-.68-.524-.811-1.673-1.1-1.11-.274-2.273-.522-2.273-2.011,0-1.177,1.045-1.986,2.6-1.986C704.08,353.9,705.072,354.689,705.086,356.165Z"
                />
                <path
                  id="Path_2670"
                  data-name="Path 2670"
                  d="M711.387,358.738h1.528a2.9,2.9,0,0,1-3.057,2.2c-2.025,0-3.213-1.45-3.213-3.592a3.133,3.133,0,0,1,3.186-3.436,2.926,2.926,0,0,1,2.978,2.26,5.209,5.209,0,0,1,.183,1.554H708.12c.053,1.5.81,2.117,1.738,2.117A1.427,1.427,0,0,0,711.387,358.738Zm-3.24-2H711.5A1.663,1.663,0,0,0,709.831,355C708.93,355,708.277,355.59,708.147,356.74Z"
                />
                <path
                  id="Path_2671"
                  data-name="Path 2671"
                  d="M717.753,359.535V360.7a3.288,3.288,0,0,1-.837.118c-1.4,0-2.012-.588-2.012-2.038v-3.54h-1.11V354.1h1.11v-1.659h1.489V354.1h1.294v1.138h-1.294v3.317c0,.745.249,1.046.9,1.046A1.486,1.486,0,0,0,717.753,359.535Z"
                />
                <path
                  id="Path_2672"
                  data-name="Path 2672"
                  d="M724.3,356.165h-1.437c0-.8-.47-1.176-1.268-1.176-.692,0-1.1.3-1.1.784,0,.574.457.692,1.5.954,1.215.313,2.508.6,2.508,2.182,0,1.2-1.071,2.024-2.782,2.024-1.777,0-2.861-.8-2.875-2.378h1.516c0,.81.5,1.267,1.385,1.267.758,0,1.2-.326,1.2-.822,0-.68-.523-.811-1.672-1.1-1.111-.274-2.273-.522-2.273-2.011,0-1.177,1.044-1.986,2.6-1.986C723.294,353.9,724.286,354.689,724.3,356.165Z"
                />
              </g>
            </g>
          </svg>
        </a>
      </li>
      <WPLikeMenuItem v-bind:menuItemData="menuItem" v-for="menuItem in menuData" />
    </ul>
  </div>
</template>

<script>
import WPLikeMenuItem from "./elements/WPLikeMenuItem";

const menuData = [
  {
    name: "Projects",
    link: "https://www.fintelum.com/tokenised-projects/"
  },
  {
    name: "Solutions",
    link: "#",
    children: [
      {
        name: "Fundraise",
        link: "https://www.fintelum.com/solutions/fundraise",
        class: "fundraise"
      },
      {
        name: "Invest",
        link: "https://www.fintelum.com/solutions/invest",
        class: "invest"
      },
      {
        name: "White-label",
        link: "https://www.fintelum.com/solutions/white-label",
        class: "white-label"
      },
      {
        name: "Development",
        link: "https://www.fintelum.com/solutions/development",
        class: "development"
      },
      {
        name: "Advisory",
        link: "https://www.fintelum.com/solutions/advisory",
        class: "advisory"
      }
    ]
  },
  {
    name: "Resources",
    link: "#",
    children: [
      {
        name: "How it works?",
        link: "https://www.fintelum.com/resources/how-it-works",
        class: "how-it-works"
      },
      {
        name: "Whitepaper",
        link: "https://www.fintelum.com/resources/whitepaper",
        class: "whitepaper"
      }
    ]
  },
  {
    name: "Company",
    link: "#",
    children: [
      {
        name: "About",
        link: "https://www.fintelum.com/company/about",
        class: "about"
      },
      {
        name: "Team",
        link: "https://www.fintelum.com/company/team",
        class: "team"
      },
      {
        name: "Referral",
        link: "https://www.fintelum.com/company/affiliate",
        class: "affiliate"
      },
      {
        name: "Careers",
        link: "https://www.fintelum.com/company/careers",
        class: "careers"
      }
    ]
  },
  {
    name: "News",
    link: "https://news.fintelum.com/"
  }
];

export default {
  name: "WPLikeMenu",
  components: {
    WPLikeMenuItem
  },
  data() {
    return {
      menuData
    };
  },
  props: {
    logo: {
      type: Boolean,
      default: false
    }
  }
};
</script>
