<template>
  <div class="platform-footer platform-controls">
    <div class="controls">
      <div class="seperator"></div>
      <div @click="toggleTheme" class="theme-button control-button">
        <i class="trading"></i>
      </div>
    </div>
  </div>
</template>

<script>

    const Themes = {
        LIGHT: "theme-light",
        DARK: "theme-dark"
    };
    const ThemeColors = {
        LIGHT: "#ffffff",
        DARK: "#161718"
    };

    export default {
        name: "Footer",
        data() {
            return {
                theme: localStorage.theme || Themes.LIGHT
            };
        },
        mounted() {
            this.setTheme(this.theme);
        },
        methods: {
            setTheme(theme) {
                if (theme === Themes.DARK) {
                    document.body.classList.remove(Themes.LIGHT);
                } else {
                    document.body.classList.remove(Themes.DARK);
                }
                document.body.classList.add(theme);
                this.theme = theme;
                localStorage.theme = theme;
            },
            toggleTheme() {
                if (this.theme === Themes.DARK) {
                    this.setTheme(Themes.LIGHT);
                } else {
                    this.setTheme(Themes.DARK);
                }
            }
        }
    }
</script>
