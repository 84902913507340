<template>
  <div class="form-row captcha" id="captcha-placeholder">
    <div class="captcha-field flex-center">
      <div class="captcha-img">
        <img alt="captcha" data-src="/captcha" id="captcha-img" v-bind:src="captchaUrl"/>
      </div>
      <div class="captcha-control">
        <i @click="refreshCaptcha()" class="ic-reload"/>
      </div>
    </div>
    <div class="input">
      <input name="captcha"
             placeholder="Captcha keyword"
             type="text"
             v-model="captcha"
             v-on:input="onChange"/>
      <div class="message error" v-if="error" v-text="error"/>
    </div>
  </div>
</template>

<style scoped>

</style>


<script>
    import store from "@/store/";
    import restConnector from "../api/restConnector";

    export default {
        name: "Captcha",
        props: {
            value: {
                type: String,
                default: '',
                required: true
            },
            error: {
                type: String,
                default: undefined,
            },
            captchaUrl: {
                type: String,
                default: '/captcha',
            },
        },
        data() {
            return {
                store,
                captcha: ""
            }
        },
        mounted() {
            this.captcha = this.value;
            this.refreshCaptcha();
        },
        methods: {
            onChange: function () {
                this.$emit('input', this.captcha);
            },
            refreshCaptcha() {
                restConnector.refreshCaptcha()
                    .then(
                        () => restConnector.loadCaptchaImg()
                            .then((response) => this.captchaUrl = response)
                            .catch(
                                (error) => console.log(
                                    'Captcha refreshCaptcha -> loadCaptchaImg error ',
                                    error
                                )
                            )
                    )
                    .catch(
                        (error) => console.log(
                            'Captcha refreshCaptcha error ',
                            error
                        )
                    )
            },
        }
    }
</script>
