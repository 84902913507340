<template>
  <form v-on:submit.prevent="loginUser()">
    <div class="form-row">
      <div class="label">Username</div>
      <div class="input ico-r">
        <input :disabled="loginInProgress || store.state.show2FA"
               autofocus
               id="username"
               name="username"
               placeholder="Username"
               ref="username"
               type="text"
               v-model="formData.username"/>
        <i class="ic-profile"/>
        <div class="message error"
             v-show="store.state.usernameError !== undefined"
             v-text="store.state.usernameError"/>
      </div>
    </div>

    <div class="form-row">
      <div class="label">Password</div>
      <div class="input ico-r">
        <input :disabled="loginInProgress || store.state.show2FA"
               id="password"
               name="password"
               placeholder="Password"
               type="password"
               v-model="formData.password"/>
        <i class="ic-lock"/>
        <div class="message error"
             v-show="store.state.passwordError !== undefined"
             v-text="store.state.passwordError"/>
      </div>
    </div>

    <TWOFA :disabled="loginInProgress"
           ref="f2a"
           v-bind:error="store.state.TWOFAError"
           v-if="store.state.show2FA"
           v-model="formData.token"/>

    <Captcha v-if="store.state.showCaptcha"
             v-model="formData.captcha"/>

    <div class="button-row">
      <button class="primary" type="submit">Login</button>
      <div class="hint">
        <span>Forgot password?</span>
        <a class="button primary pill" v-bind:href="CHANGE_PASSWORD_LINK_PATH">Recover</a>
      </div>
    </div>

    <div class="button-row">
      <div class="hint">
        <span>If you have any problems or questions, please contact <a href="mailto:support@fintelum.com">support@fintelum.com</a></span>
      </div>
    </div>
  </form>
</template>

<script>
    import {CHANGE_PASSWORD_LINK_PATH} from "@/constants";
    import store from "@/store/";
    import restConnector from "../api/restConnector";
    import Captcha from './Captcha';
    import TWOFA from "@commons/components/elements/TWOFA.vue";

    export default {
        name: "Login",
        components: {
            TWOFA,
            Captcha,
        },
        data() {
            return {
                CHANGE_PASSWORD_LINK_PATH,
                store,

                loginInProgress: false,

                formData: {
                    username: undefined,
                    password: undefined,
                    token: undefined,
                    captcha: undefined,
                    resetKey: undefined,
                }
            };
        },
        destroyed() {
            store.state.usernameError = undefined;
            store.state.passwordError = undefined;
            store.state.TWOFAError = undefined;
            store.state.show2FA = false;
            store.state.showCaptcha = false;
        },
        methods: {
            loginUser: async function () {
                if (this.loginInProgress) return;

                this.loginInProgress = true;

                await restConnector.loginPost(this.formData)
                    .then((nextPage) => window.location.href = nextPage)
                    .catch((error) => store.setFieldErrors(error))
                    .finally(
                        () => {
                            this.loginInProgress = false;

                            if (store.state.show2FA) {
                                setTimeout(
                                    () => this.$nextTick(() => this.$refs['f2a'].selectInput(0)),
                                    200
                                );
                            }
                        }
                    );
            }
        },
    }
</script>
