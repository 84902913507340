import Vue from "vue";
import init from '@/init';
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import ClickOutside from "v-click-outside";
import SmoothScrollbar from "vue-smooth-scrollbar";
import {CoolSelectPlugin} from 'vue-cool-select';

Vue.config.productionTip = false;
// TODO: Set this to false for PRODUCTION
Vue.config.devtools = true;

Vue.config.productionTip = false;

Vue.use(ClickOutside);
Vue.use(SmoothScrollbar);
Vue.use(CoolSelectPlugin);

export default new Vue({
    data: {
        store
    },
    router,
    beforeMount() {
        init();
    },
    render: h => h(App)
}).$mount("#app");
