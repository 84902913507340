<template>
  <div class="form-row token-placeholder">
    <div class="code-input">
      <div class="label" v-if="label_text" v-text="label_text"/>
      <div class="code-input-field">
        <template v-for="(tokenPart, tokenI) in tokenParts">
          <input :data-id="tokenI"
                 :id="'token_'+tokenI"
                 :maxlength="tokenCount"
                 :ref="'token_'+tokenI"
                 @keydown="onKeyDown($event)"
                 @keyup="onKeyUp($event)"
                 class="two-fa"
                 minlength="1"
                 :disabled="disabled"
                 type="tel"
                 v-model="tokenParts[tokenI]"
                 v-on:input="onChange"/>
        </template>
        <div class="message" v-if="message" v-text="message"/>
        <div class="message error" v-if="error" v-text="error"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
    const BACKSPACE_KEY = 8;
    const LEFT_ARROW_KEY = 37;
    const UP_ARROW_KEY = 38;
    const RIGHT_ARROW_KEY = 39;
    const DOWN_ARROW_KEY = 40;
    const E_KEY = 69;
    const CMD_KEY = 91;
    const CTRL_KEY = 17;

    export default {
        name: "TWOFA",
        props: {
            value: {
                type: String,
                default: '',
                required: true
            },
            tokenCount: {
                type: Number,
                default: 6
            },
            error: {
                type: String,
                default: undefined,
            },
            message: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label_text: {
                type: String,
                default: 'Two-Factor Authenticator'
            }
        },
        data() {
            return {
                tokenParts: new Array(this.tokenCount),
                ctrlDown: false
            };
        },
        methods: {
            selectInput: function (tokenI) {
                const element = this.$refs["token_" + tokenI][0];

                element.focus();
                element.select();
            },

            output: function () {
                this.$emit('input', this.tokenParts.join(''));
            },

            onChange: function (event) {
                let value = String(event.target.value);
                const currentTokenI = Number(event.target.dataset.id);

                /** Filter Chars */
                value = String(value.replace(/[^0-9]/g, ''))
                    .split('')
                    .filter(currChar => !['-', '.', ' '].includes(currChar))
                    .join('');

                if (value !== '') {
                    if (value.length > 1) {
                        value.split('')
                            .map(
                                (chart, i) => {
                                    if (currentTokenI + i < this.tokenCount) {
                                        this.$set(this.tokenParts, (currentTokenI + i), chart);
                                        this.selectNextInput(currentTokenI + i);
                                    }
                                    return false;
                                }
                            );
                    } else {
                        this.$set(this.tokenParts, currentTokenI, value);
                        this.selectNextInput(currentTokenI);
                    }
                } else {
                    this.$set(this.tokenParts, currentTokenI, value);
                }
            },

            selectNextInput(currentTokenI) {
                if (currentTokenI + 1 === this.tokenParts.length) {
                    this.output();
                    return;
                }

                const newTargetKey = currentTokenI < this.tokenParts.length
                    ? currentTokenI + 1
                    : undefined;

                if (newTargetKey !== undefined) {
                    this.$nextTick(() => this.selectInput(newTargetKey));
                }
            },
            selectPrevInput(currentTokenI) {
                const newTargetKey = currentTokenI < this.tokenParts.length && currentTokenI > 0
                    ? currentTokenI - 1
                    : undefined;

                if (newTargetKey !== undefined) {
                    this.$nextTick(() => this.selectInput(newTargetKey));
                }
            },

            onKeyUp: function (event) {
                switch (event.keyCode) {
                    case CMD_KEY:
                    case CTRL_KEY:
                        this.ctrlDown = false;
                        break;
                }
            },
            onKeyDown: function (event) {
                const currentTokenI = Number(event.target.dataset.id);

                [189, 190].map((item) => {
                    if (item === event.keyCode) {
                        event.preventDefault();
                        return true;
                    }
                });

                switch (event.keyCode) {
                    case CMD_KEY:
                    case CTRL_KEY:
                        this.ctrlDown = true;
                        break;

                    case BACKSPACE_KEY:
                        event.preventDefault();
                        if (this.tokenParts[currentTokenI] === undefined) {
                            this.selectPrevInput(currentTokenI);
                            break;
                        }
                        this.$set(this.tokenParts, currentTokenI, undefined);
                        break;

                    case LEFT_ARROW_KEY:
                        event.preventDefault();
                        this.selectPrevInput(currentTokenI);
                        break;

                    case RIGHT_ARROW_KEY:
                        event.preventDefault();
                        this.selectNextInput(currentTokenI);
                        break;

                    case UP_ARROW_KEY:
                        event.preventDefault();
                        break;

                    case DOWN_ARROW_KEY:
                        event.preventDefault();
                        break;

                    case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
                        if (event.target.type === 'number') {
                            event.preventDefault();
                            break;
                        }

                    default:
                        break;
                }

                // this.handleTouch(value); // TODO: TOUCH INPUT HANDLING
            },
        }
    }
</script>
