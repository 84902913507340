/**
 * This is a JS connector for REST API.
 */
import {CAPTCHA_REFRESH_PATH, CAPTCHA_PATH, ErrorMessages, LOGIN_POST_PATH, REGISTER_POST_PATH} from "@/constants";
import store from "@/store/";
import axios from "axios";


const http = axios.create({
    // baseURL: REST_API_URL,
    headers: {
        "Content-type": "application/json"
    },
    withCredentials: true,
});
const checkResponseErrors = response => {
    if (!response || !response.data) {
        throw new Error(ErrorMessages.EMPTY_RESPONSE_DATA);
    }
    if (!response.data.success && response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
    }
};

export default {
    loginPost: (data) => new Promise(async (resolve, reject) => {
        const config = {params: {}};
        config.params[appContext.icoLogin.csrfParam] = appContext.icoLogin.csrf;
        try {
            const response = await http.post(LOGIN_POST_PATH, data, config);
            checkResponseErrors(response);

            if (response.data.token) {
                store.state.show2FA = true;
                reject({});
            } else if (response.data.success) {
                resolve(response.data.nextPage);
            } else {
                if (response.captcha) {
                    store.state.showCaptcha = true;
                }

                if (response.data.fieldErrors) {
                    reject(response.data.fieldErrors);
                }
            }
            reject({});
        } catch (e) {
            reject(e);
        }
    }),
    registerPost: (data) => new Promise(async (resolve, reject) => {
        const config = {params: {}};
        config.params[appContext.icoUserCreate.csrfParam] = appContext.icoUserCreate.csrf;
        try {
            const response = await http.post(REGISTER_POST_PATH, data, config);
            checkResponseErrors(response);

            if (response.data.success) {
                resolve(response.data.nextPage);
            } else {
                if (response.data.captcha) {
                    store.state.showCaptcha = true;
                }

                if (response.data.fieldErrors) {
                    reject(response.data.fieldErrors);
                } else if (response.data.errorMessage) {
                    throw new Error(response.data.errorMessage);
                } else {
                    throw new Error(ErrorMessages.INTERNAL_ERROR);
                }
                reject({});
            }
        } catch (e) {
            reject(e);
        }
    }),

    loadCaptchaImg: () => new Promise(async (resolve, reject) => {
        const config = {
            responseType: 'arraybuffer'
        };
        try {
            const response = await http.get(CAPTCHA_PATH + '?' + new Date().getTime(), config);
            checkResponseErrors(response);

            let bytes = new Uint8Array(response.data);
            let binary = bytes.reduce(
                (data, b) => data += String.fromCharCode(b),
                ''
            );

            resolve("data:image/jpeg;base64," + btoa(binary));
        } catch (e) {
            reject(e);
        }
    }),
    refreshCaptcha: () => new Promise(async (resolve, reject) => {
        try {
            const response = await http.get(CAPTCHA_REFRESH_PATH);
            checkResponseErrors(response);

            if (response.data.success) {
                resolve(response);
            }

            throw new Error(ErrorMessages.EMPTY_RESPONSE_DATA);
        } catch (e) {
            reject(e);
        }
    }),
};
