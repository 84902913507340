<template>
  <ul class="wp-like-sub-menu">
    <WPLikeSubMenuItem v-bind:submenuItemData="menuItem"
                       v-for="menuItem in subMenuItemsData"/>
  </ul>
</template>

<script>
    import WPLikeSubMenuItem from "./WPLikeSubMenuItem";

    export default {
        name: "WPLikeSubMenu",
        components: {
            WPLikeSubMenuItem
        },
        props: {
            subMenuItemsData: {
                type: Array,
                default: []
            }
        },
    }
</script>
