<template>
    <div ref="notification" class='notification' :class='[type,open]' :style="{top: top}">
        {{message}}</div>
</template>

<script>
import { MessageTypes } from '../../../constants';

export default {
  name: 'Message',
  data() {
    return {
      open: '',
      top: '',
    };
  },
  props: {
    type: String,
    message: String,
    stayAlive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSuccess(message) {
      if (message) {
        this.message = message;
      }
      this.type = MessageTypes.SUCCESS;
      setTimeout(() => {
        this.close();
      }, 3000);
    },
    close() {
      this.open = false;
      this.top = `${this.$refs.notification.style.top || '70px'}!important`;
      setTimeout(() => {
        document.body.removeChild(this.$el);
        this.$destroy();
      }, 300);
    },
  },
  mounted() {
    setTimeout(() => {
      this.open = 'open';
    }, 50);
    if (!this.stayAlive) {
      setTimeout(() => {
        this.close();
      }, 1500);
    }
  },
};
</script>
