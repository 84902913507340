<template>
  <div class="page ico-login">
    <div class="navigation">
      <div class="logo">
        <a href="https://www.fintelum.com/" style="color: inherit;">
        <i class="ic-logo">
      </div>
    </div>

    <WPLikeMenu :logo="true"/>

    <main>
      <div class="full-fixed flex-center" v-if="store.isHttpRequestInProgress()">
        <div class="loading">
          <div class="el-loading-spinner">
            <svg class="circular" viewBox="25 25 50 50">
              <circle class="path" cx="50" cy="50" fill="none" r="20"/>
            </svg>
            <p class="el-loading-text">
              Processing...
            </p>
          </div>
        </div>
      </div>

      <div :class="{'login-active': store.state.activeTab === LOGIN_TAB, 'register-active': store.state.activeTab === REGISTER_TAB}"
           class="login flex-center"
           v-show="!store.isHttpRequestInProgress()">
        <div class="content">
          <div class="flex-center">
            <div class="tabs flex-center">
              <div :class="{active: store.state.activeTab === REGISTER_TAB}"
                   @click="store.switchTab(REGISTER_TAB)"
                   class="tab">
                <div class="icon">
                  <svg class="injected-svg"
                       data-src="//images/user-square.svg"
                       viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g data-name="New profile Dark (active)" id="New_profile_Dark_active_" transform="translate(-1150 -416)">
                      <path class="cls-1"
                            d="M1918-1194h-12a6.007,6.007,0,0,1-6-6v-12a6.007,6.007,0,0,1,6-6h12a6.007,6.007,0,0,1,6,6v12A6.007,6.007,0,0,1,1918-1194Zm-2.566-6.978a.815.815,0,0,1,.384.111l3.773,2.7a.97.97,0,0,0,.525.163.831.831,0,0,0,.733-.453.989.989,0,0,0-.27-1.352l-3.773-2.7a2.457,2.457,0,0,0-1.354-.414,2.267,2.267,0,0,0-1.161.317,5.537,5.537,0,0,1-2.2.362,5.537,5.537,0,0,1-2.2-.362,3.164,3.164,0,0,0-1.233-.269,2.154,2.154,0,0,0-1.372.463l-3.863,2.607a.908.908,0,0,0-.4.59.873.873,0,0,0,.134.665.793.793,0,0,0,.719.482,1.078,1.078,0,0,0,.539-.1l3.863-2.607a.971.971,0,0,1,.438-.112.619.619,0,0,1,.37.112,6.631,6.631,0,0,0,2.831.533,7.209,7.209,0,0,0,3.188-.63A.567.567,0,0,1,1915.434-1200.979ZM1912.271-1214a4.713,4.713,0,0,0-4.7,4.828,4.713,4.713,0,0,0,4.7,4.828,4.714,4.714,0,0,0,4.7-4.828A4.714,4.714,0,0,0,1912.271-1214Zm0,7.726a2.772,2.772,0,0,1-2.008-.833,2.934,2.934,0,0,1-.81-2.064,2.934,2.934,0,0,1,.81-2.064,2.772,2.772,0,0,1,2.008-.833,2.773,2.773,0,0,1,2.008.833,2.934,2.934,0,0,1,.81,2.064,2.934,2.934,0,0,1-.81,2.064A2.773,2.773,0,0,1,1912.271-1206.275Z"
                            data-name="Subtraction 25"
                            id="Subtraction_25"
                            transform="translate(-750 1634)"/>
                    </g>
                  </svg>
                </div>

                <Media :query="{minWidth: 578}">
                  <span>New Fintelum profile</span>
                </Media>

                <Media :query="{maxWidth: 577}">
                  <span>New profile</span>
                </Media>
              </div>

              <div :class="{active: store.state.activeTab === LOGIN_TAB}"
                   @click="store.switchTab(LOGIN_TAB)"
                   class="tab">
                <i class="ic-logo-icon"></i>

                <Media :query="{minWidth: 578}">
                  <span>Log in with Fintelum</span>
                </Media>

                <Media :query="{maxWidth: 577}">
                  <span>Log in</span>
                </Media>
              </div>
            </div>
          </div>

          <Login v-if="store.state.activeTab === LOGIN_TAB"/>
          <Register v-if="store.state.activeTab === REGISTER_TAB"/>
        </div>
      </div>
    </main>

    <Footer/>
  </div>
</template>

<style lang="sass">
  @import '@commons/assets/_sass/global.scss'
  @import '@commons/assets/_sass/parts/wp-like-menu.scss'

  #app

    .page.ico-login
      .wp-like-menu-container
        width: 100vw !important

      .navigation
        @media screen and (max-width: 1023px)
          display: flex !important

        @media screen and (min-width: 1023px)
          display: none

      main
        .login
          &.register-active
            padding-top: 90px

          &.login-active
            transform: translate(0, -50%)
            top: calc(50% + 50px)
            position: relative
            padding: unset

            @media screen and (max-width: 1023px)
              top: 50%

      .loading
        display: block
        width: 100%

        .el-loading-spinner
          top: 50%
          margin-top: -21px
          width: 100%
          text-align: center
          position: absolute

          .circular
            width: 42px
            height: 42px
            animation: loading-rotate 2s linear infinite

            .path
              animation: loading-dash 1.5s ease-in-out infinite
              stroke-dasharray: 90, 150
              stroke-dashoffset: 0
              stroke-width: 2
              stroke-linecap: round
              stroke: $primary

          p.el-loading-text
            margin: 3px 0
            font-size: 14px
            // color: $primary;
            @include color('primary')

  @keyframes loading-rotate
    100%
      transform: rotate(360deg)

  @keyframes loading-dash
    0%
      stroke-dasharray: 1, 200
      stroke-dashoffset: 0

    50%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -40px

    100%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -120px
</style>

<script>
    import {LOGIN_TAB, REGISTER_TAB} from "@/constants";
    import store from "@/store/";
    import Footer from "@commons/components/Footer.vue";
    import WPLikeMenu from "@commons/components/WPLikeMenu.vue";
    import Media from "vue-media";
    import Login from "../components/Login";
    import Register from "../components/Register";

    export default {
        name: "LoginStart",
        components: {
            Login,
            Register,
            Footer,
            Media,
            WPLikeMenu
        },
        data() {
            return {
                LOGIN_TAB,
                REGISTER_TAB,
                store
            };
        },
    }
</script>
