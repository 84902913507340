<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="sass">
  @import '@commons/assets/_sass/mixins.scss'
  @import '@commons/assets/_sass/media.scss'
  @import '@commons/assets/_sass/global.scss'
  @import '@commons/assets/_sass/fonts.scss'
  @import '@commons/assets/_sass/elements.scss'
  @import '@commons/assets/_sass/icons/variables.scss'
  @import '@commons/assets/_sass/pages/platform.scss'
  @import '@commons/assets/_sass/parts/navigation.scss'
  @import '@commons/assets/_sass/page.scss'
  @import '@commons/assets/_sass/pages/login.scss'

  body
    &.theme-light
      .page-head
        background-color: #FFF

    &.theme-dark
      input
        border-top: 1px solid #161718

        @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm)
          border-top: unset

      input:-webkit-autofill
        background-color: #161718 !important
        -webkit-text-fill-color: #FFF !important
        -webkit-box-shadow: 0 0 0 1000px #161718 inset !important
        border-top: 1px solid #161718 !important
        border-top-left-radius: 0 !important
        border-top-right-radius: 0 !important
        transition: unset !important

        @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm)
          border-top: unset

      .page-head
        background-color: #161718

    #app
      > .page
        padding: 0
</style>

<script>
    export default {
        name: "ICO-LOGIN",
    };
</script>
