<template>
  <li class="wp-like-menu-item">
    <a :href="menuItemData.link"
       class="wp-like-menu-item-link"
       v-text="menuItemData.name"/>
    <WPLikeSubMenu v-bind:subMenuItemsData="menuItemData.children"
                   v-if="menuItemData.children && menuItemData.children.length > 0"/>
  </li>
</template>

<script>
    import WPLikeSubMenu from "./WPLikeSubMenu";

    export default {
        name: "WPLikeMenuItem",
        props: {
            menuItemData: {
                name: {
                    type: String,
                    default: 'Menu Item',
                },
                link: {
                    type: String,
                    default: '#',
                },
                class: {
                    type: String,
                    default: undefined,
                },
                children: {
                    type: Array,
                    default: undefined,
                },
            }
        },
        components: {
            WPLikeSubMenu
        },
    }
</script>
