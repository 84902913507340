<template>
  <li class="wp-like-sub-menu-item">
    <a :class="submenuItemData.class"
       :href="submenuItemData.link"
       class="wp-like-sub-menu-item-link"
       v-text="submenuItemData.name"/>
  </li>
</template>

<script>
    import WPLikeSubMenu from "./WPLikeSubMenu";

    export default {
        name: "WPLikeSubMenuItem",
        props: {
            submenuItemData: {
                name: {
                    type: String,
                    default: 'Menu Item',
                },
                link: {
                    type: String,
                    default: '#',
                },
                class: {
                    type: String,
                    default: undefined,
                },
            }
        },
        components: {
            WPLikeSubMenu
        },
    }
</script>
