<template>
  <form class="register" v-on:submit.prevent="registerUser()">
    <div class="form-container">
      <div class="form-row">
        <div class="label">E-mail address</div>
        <div class="input ico-r">
          <input
            id="email"
            name="email"
            placeholder="Enter e-mail address to be used as username"
            type="text"
            v-model="formData.email"
            tabindex="1"
          />
          <i class="ic-profile" />
          <div
            class="message error"
            v-show="store.state.usernameError !== undefined"
            v-text="store.state.usernameError"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <div class="col-1">
            <div class="label">Country code</div>
            <div class="input">
              <cool-select
                :filter="filter"
                :items="countries"
                :placeholder="hasChosenCountry ? '' : 'Code'"
                item-text="key"
                item-value="key"
                menu-default-position="bottom"
                v-model="formData.countryCode"
                tabindex="2"
              >
                <template slot="item" slot-scope="{ item: country }">
                  <div style="display: flex; align-items: center;">
                    <div class="select-value-label">
                      <span class="country-code">
                        <img
                          :alt="country.value"
                          :src="
                            'https://static.fintelum.com/static/images/flags/' +
                              country.key +
                              '.svg'
                          "
                        />
                        {{ country.key }}
                      </span>

                      <span class="phone-code">{{ country.value }}</span>
                    </div>
                  </div>
                </template>

                <template slot="selection" slot-scope="{ item: country }">
                  <div class="select-value-label" style="position: absolute; top: 40px; left: 15px">
                    <span class="country-code">
                      <img
                        :alt="country.value"
                        :src="
                          'https://static.fintelum.com/static/images/flags/' + country.key + '.svg'
                        "
                      />
                      {{ country.key }}
                    </span>

                    <span class="phone-code">{{ country.value }}</span>
                  </div>
                </template>
              </cool-select>

              <div
                class="message error"
                v-show="store.state.countryCodeError !== undefined"
                v-text="store.state.countryCodeError"
              />
            </div>
          </div>

          <div class="col-2">
            <div class="label">Phone number</div>
            <div class="input ico-r">
              <input
                :disabled="!hasChosenCountry"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter phone number"
                type="text"
                v-model="formData.phoneNumber"
                tabindex="3"
              />
              <i class="ic-mobile" />
              <div
                class="message error"
                v-show="store.state.phoneNumberError !== undefined"
                v-text="store.state.phoneNumberError"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="label">Password</div>
        <div class="input ico-r password">
          <input
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            v-model="formData.password"
            tabindex="4"
          />
          <i class="ic-lock" />
          <div
            class="message error"
            v-show="store.state.passwordError !== undefined"
            v-text="store.state.passwordError"
          />
          <!-- TODO: create password strength line -->
          <!-- <div class="line">
                            <div class="warning" style="width:59%"/
                        </div> -->
        </div>
      </div>

      <div class="form-row">
        <div class="label">Password confirmation</div>
        <div class="input ico-r">
          <input
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Enter password confirmation"
            type="password"
            v-model="formData.passwordConfirmation"
            tabindex="5"
          />
          <i class="ic-lock" />
          <div
            class="message error"
            v-show="passwordConfirmationError !== undefined"
            v-text="passwordConfirmationError"
          />
        </div>
      </div>
    </div>

    <div class="form-row terms-form">
      <div class="page-head flex-center">
        <h2>Fintelum Terms</h2>
        <div class="head-button save">
          <a :href="store.state.companyTermsUrl" class="button md-button transp" target="_blank">
            <i class="ic-doc-export"></i>
            Save .pdf
          </a>
        </div>
      </div>

      <div class="textarea-block multi bradius">
        <div class="textarea terms">
          <scroll-view
            :options="{ alwaysShowTracks: true }"
            :style="{ width: '100%', height: '100%', 'min-height': 300 }"
            @endy="enableCheckCompanyTerms"
            ref="scrollbarCompTerms"
          >
            <div class="terms-content" v-html="store.state.companyTerms" />
          </scroll-view>
        </div>

        <div class="block-footer">
          <div class="check">
            <div class="check-label">
              <div class="checkbox">
                <input
                  :disabled="store.state.canCheckCompTerms === false"
                  :value="store.state.checkedCompTerms"
                  class="hidden"
                  tabIndex="0"
                  type="checkbox"
                  v-model="store.state.checkedCompTerms"
                />
                <label />
                <div class="placeholder">I have read and accept the terms</div>
              </div>
            </div>
          </div>
          <div
            class="message error"
            v-show="showTosError"
            v-text="
              !store.state.canCheckCompTerms ? ErrorMessages.READ_TERMS : ErrorMessages.AGREE_TERMS
            "
          />
        </div>

        <div class="textarea">
          <div class="terms-content" v-html="store.state.promoAcceptText" />
        </div>

        <div class="block-footer">
          <div class="check">
            <div class="check-label">
              <div class="checkbox">
                <input
                  :value="store.state.checkedPromoText"
                  class="hidden"
                  tabIndex="0"
                  type="checkbox"
                  v-model="store.state.checkedPromoText"
                />
                <label />
                <div class="placeholder">I accept to receive updates</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Captcha v-if="store.state.showCaptcha" v-model="formData.captcha" />

    <div class="button-row">
      <button class="primary" type="submit">Create account</button>
    </div>

    <div class="button-row">
      <div class="hint">
        <span
          >If you have any problems or questions, please contact
          <a href="mailto:support@fintelum.com">support@fintelum.com</a></span
        >
      </div>
    </div>
  </form>
</template>

<style lang="sass">
@import '@commons/assets/_sass/global.scss'
@import '@commons/assets/_sass/mixins.scss'
@import '@commons/assets/_sass/media.scss'

body
  &.theme-dark
    .country-code,
    .phone-code
      color: #FFFFFF

    .IZ-select__menu
      background-color: #161718
      border: 1px solid rgba(206, 212, 218, 0.45)

    .IZ-select__item
      &:hover
        background-color: rgba(255, 255, 255, 0.45)

  .register
    .form-row
      .form-group
        @include flex-wrap

        .col-1
          width: 100%

          @media #{$smallTablet}
            width: 30%
            padding-right: 6px

        .col-2
          width: 100%

          @media #{$smallTablet}
            width: 70%
            padding-left: 6px


      .select-value-label
        color: #161718
        display: flex
        align-items: center
        font-weight: 400
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        font-size: 17px

        .country-code
          display: flex
          align-items: center
          font-size: 13px
          padding: 2px 6px
          border: 1px solid #edeeef
          border-radius: 3px
          cursor: pointer
          margin-right: 6px
          vertical-align: baseline

          img
            display: block
            width: 13px
            height: 13px
            border-radius: 1px
            margin-right: 6px

        .phone-code
          display: block
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          font-size: 17px

    .terms-form
      .textarea
        padding: 0 3px

    .textarea-block
      .textarea
        &.terms
          height: 300px

    .check + .message
      position: relative
      top: 0
      margin-top: -10px


  .IZ-select__input
    align-items: center
    display: flex
    flex: 1 1 auto
    flex-wrap: wrap
    width: 100%

  .IZ-select__menu
    opacity: 0
    transition: opacity 0.1s
    position: absolute
    z-index: 8
    transform-origin: left top 0
    background-color: #fff
    border: 1px solid #ced4da
    border-radius: .25rem
    box-shadow: 0 2px 11px -2px rgba(0, 0, 0, 0.19)

    &--at-top
      border-bottom: 0
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0

      &.IZ-select__menu--disable-search
        border-bottom: 1px
        border-bottom-left-radius: .25rem
        border-bottom-right-radius: .25rem

    &--at-bottom
      border-top: 0
      border-top-left-radius: 0
      border-top-right-radius: 0

      &.IZ-select__menu--disable-search
        border-top: 1px
        border-top-left-radius: .25rem
        border-top-right-radius: .25rem

    .IZ-select__menu-items
      overflow-y: auto
      overflow-x: hidden

    .IZ-select__no-data
      margin: 0 10px

  .IZ-select__item
    cursor: pointer
    padding: 10px 14px
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

    &:hover
      background-color: #f2f2f2

    &.IZ-select__item--selected
      color: #1976d2 !important

  .IZ-select__error
    margin-top: .55rem
    font-size: 85%
    color: #dc3545
</style>

<script>
import { ErrorMessages } from "@/constants";
import store from "@/store/";
import ScrollView from "@blackbp/vue-smooth-scrollbar";
import { ErrorMessage } from "@commons/components/elements/message/";
import { CoolSelect } from "vue-cool-select";
import restConnector from "../api/restConnector";
import Captcha from "./Captcha";

export default {
  name: "Register",
  components: {
    Captcha,
    ScrollView,
    CoolSelect
  },
  data() {
    return {
      ErrorMessages,
      store,

      showTosError: false,
      passwordConfirmationError: undefined,

      formData: {
        email: undefined,
        countryCode: undefined,
        phoneNumber: undefined,
        password: undefined,
        passwordConfirmation: undefined,
        captcha: undefined
      }
    };
  },
  destroyed() {
    store.state.usernameError = undefined;
    store.state.phoneNumberError = undefined;
    store.state.countryCodeError = undefined;
    store.state.passwordError = undefined;
    store.state.showCaptcha = false;
    store.state.checkedCompTerms = false;
    // store.state.canCheckCompTerms = false;
    store.state.checkedPromoText = false;
    store.state.httpRequestInProgress = false;
  },
  computed: {
    countries() {
      return this.store.state.countries
        .filter(country => country.code !== "##")
        .map(country => ({
          key: country.code,
          value: "+" + country.callCode,
          original: country
        }));
    },
    hasChosenCountry() {
      return !!this.formData.countryCode && this.formData.countryCode !== "";
    }
  },
  methods: {
    filter(item, queryText, itemText) {
      const hasValue = val => (val != null ? val : "");

      const text = hasValue(item.key);
      const text2 = hasValue(item.value);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 ||
        text2
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    registerUser: async function() {
      this.resetErrors();

      if (this.store.state.httpRequestInProgress === true || !this.isValidForm()) return;

      this.$nextTick(() => (this.store.state.httpRequestInProgress = true));

      await restConnector
        .registerPost(this.formData)
        .then(nextPage => (window.location.href = nextPage))
        .catch(error => store.setFieldErrors(error))
        .finally(() => this.$nextTick(() => (this.store.state.httpRequestInProgress = false)));
    },
    resetErrors() {
      this.$nextTick(() => {
        this.store.state.usernameError = undefined;
        this.store.state.phoneNumberError = undefined;
        this.store.state.countryCodeError = undefined;
        this.store.state.passwordError = undefined;
        this.passwordConfirmationError = undefined;
        this.showTosError = false;
      });
    },
    isValidForm() {
      let valid = true;

      if (!this.formData.email) {
        this.$nextTick(() => (this.store.state.usernameError = ErrorMessages.EMAIL_NOT_PROVIDED));
        valid = false;
      }

      if (!this.hasChosenCountry) {
        this.$nextTick(
          () => (this.store.state.countryCodeError = ErrorMessages.COUNTRY_NOT_PROVIDED)
        );
        valid = false;
      }

      if (!this.formData.phoneNumber) {
        this.$nextTick(
          () => (this.store.state.phoneNumberError = ErrorMessages.PHONE_NUMBER_NOT_PROVIDED)
        );
        valid = false;
      }

      if (!this.formData.password) {
        this.$nextTick(
          () => (this.store.state.passwordError = ErrorMessages.PASSWORD_NOT_PROVIDED)
        );
        valid = false;
      }

      if (!this.formData.passwordConfirmation) {
        this.$nextTick(
          () => (this.passwordConfirmationError = ErrorMessages.PASSWORD_CONFIRM_NOT_PROVIDED)
        );
        valid = false;
      }

      if (this.formData.password !== this.formData.passwordConfirmation) {
        this.$nextTick(() => (this.passwordConfirmationError = ErrorMessages.PASSWORDS_NO_MACH));
        valid = false;
      }

      if (!this.store.state.checkedCompTerms || !this.store.state.canCheckCompTerms) {
        valid = false;
        this.$nextTick(() => (this.showTosError = true));

        if (this.store.state.canCheckCompTerms && !this.store.state.checkedCompTerms) {
          ErrorMessage(ErrorMessages.AGREE_TERMS);
        }
      }

      return valid;
    },
    enableCheckCompanyTerms() {
      this.$nextTick(() => {
        this.store.state.canCheckCompTerms = true;
        this.showTosError = false;
      });
    }
  }
};
</script>
