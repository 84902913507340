import Vue from 'vue';
import MessageTemplate from './Message.vue';
import { MessageTypes } from '../../../constants';

const MessageConstructor = Vue.extend(MessageTemplate);

export default function Message(message, type) {
  const msg = new MessageConstructor({ el: document.createElement('div'), propsData: { type, message } });
  document.body.appendChild(msg.$el);
}

export function ErrorMessage(message) {
  Message(message, MessageTypes.ERROR);
}

export function WarningMessage(message) {
  Message(message, MessageTypes.WARNING);
}

export function SuccessMessage(message) {
  Message(message, MessageTypes.SUCCESS);
}


export const StatusMessage = (message) => {
  const msg = new MessageConstructor({ el: document.createElement('div'), propsData: { message, type: MessageTypes.ERROR, stayAlive: true } });
  document.body.appendChild(msg.$el);
  return msg;
};
