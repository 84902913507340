import * as icoLogin from "./icoLoginModule";

export default {
    // STORE'S STATE
    state: {
        ...icoLogin.state
    },

    // STORE'S ACTIONS
    ...icoLogin.actions
};
